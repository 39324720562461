import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { connect, useSelector } from 'react-redux';
import { Theme, ErrorBoundary, SizerWrapper } from '@commonsku/styles';

import { getPopups, getPurchaseOrders } from '../selectors';

import Header from './Header';
import MainSection from './MainSection';
import Overlay from '../components/Overlay';
import ProductionReportView from '../components/ProductionReportView';
import POSidePanel from '../components/production-report/POSidePanel';

import { createNewFeaturePopup } from '../actions/popup';

import createPopup from '../popup-factory';

import { reducers } from '../store/configureProductionReportStore';
import withReducers from '../store/withReducers';

import { oauth } from '../utils';
import ReactTooltip from 'react-tooltip';
import {ToastContainer} from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";
import '../../scss/css/production_report.css';
import { createGlobalStyle } from '../components/helpers';

const GlobalStyle = createGlobalStyle(`
  .tasks-comment {
    .mention-textarea-container {
      textarea {
        height :50px !important 
      }
    }
  }
`);

function ProductionReportApp({ identity, onCreateNewFeaturePopup, popups, ...props }) {
    const [selectedSidePanelTab, setSelectedSidePanelTab] = useState('notes');
    const [selectedPOId, setSelectedPOId] = useState(false);
    const pos = useSelector(getPurchaseOrders);
    const selectedPO = get(pos, [selectedPOId], false);

    useEffect(function () {
        // remove resku from body and html tags
        document.getElementsByTagName('body')[0].className = '';
        document.getElementsByTagName('html')[0].className = '';
        document.body.style = "background: #eef1f4;";
        oauth('GET', `user/${identity.user_id}`, { should_show_popup: true }).then(({ json }) => {
          if (json.show_popup == 1) {
            onCreateNewFeaturePopup();
          }
        });
        injectStyle();
      }, []);

    //locking the page again when side panel is shown
    /* useEffect(() => {
      if(sidePanelRow) {
        setTimeout(() => {
          document.body.style.overflow = 'hidden'
        }, 1000)
      }else{
        setTimeout(() => {
          document.body.style.overflow = 'auto'
        }, 1000)
      }
    }, [sidePanelRow]) */

    return (
        <Theme>
            <GlobalStyle theme={{ fontFamily: "'skufont-regular', 'sans-serif', 'Roboto'" }} />
            <ErrorBoundary>
                <ToastContainer
                    autoClose={3000}
                    hideProgressBar={true}
                />
            <div className="resku">
                <Header></Header>
            </div>
            <div>
                <ReactTooltip id="commentTip" place="top">View Comments</ReactTooltip>
                <ReactTooltip id ="pinTip" place="top" />
                <ReactTooltip id="deleteTip" place="top">Delete</ReactTooltip>
                <ReactTooltip id="editTaskTip" place="top">Edit</ReactTooltip>
                <div className="csku-styles">
                    <POSidePanel
                        sidePanelRow={selectedPO}
                        setSidePanelRow={setSelectedPOId}
                        selectedTab={selectedSidePanelTab}
                        setSelectedTab={setSelectedSidePanelTab}
                    />
                </div>

                <div className="resku"><MainSection /></div>

                <div className="main-content csku-styles" style={{ marginTop: '0rem', height: '90%' }}>
                    <SizerWrapper
                        xsStyle="padding-left: 0; margin-left: 0;"
                        smStyle="padding-right: 0 !important; padding-left: 10px !important;"
                        mdStyle="padding-left: 10px;"
                        style={{ paddingRight: 0, background: '#ffffff' }}>
                        <ProductionReportView
                            sidePanelRow={selectedPO}
                            setSidePanelRow={(po) => {
                              if (!po || !get(po, ['purchase_order_id'], false)) {
                                setSelectedPOId(false);
                                return;
                              }
                              setSelectedPOId(po.purchase_order_id);
                            }}
                            setSelectedSidePanelTab={setSelectedSidePanelTab}
                        />
                    </SizerWrapper>
                </div>
                <div className="resku">
                {popups ? popups.map((p, idx) => createPopup(p, idx, props)) : null}
                </div>
            </div>
            <div className="resku">
                <Overlay popups={popups} />
            </div>
            </ErrorBoundary>
        </Theme>
    );
}

const mapStateToProps = (state, ownProps) => {
    return {
      identity: state.identity,
      popups: getPopups(state),
      loading: state.display.loading,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateNewFeaturePopup: () => {
            dispatch(createNewFeaturePopup());
        }
    };
};

export default withReducers(connect(mapStateToProps, mapDispatchToProps)(ProductionReportApp), reducers, true);
